import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { BACKEND_API, backend_api, convertToInternationalCurrencySystem, formatPrice } from "../helper/constant";
import loadScript from 'load-script';
import { AuthContext } from "../providers/AuthProvider";

export default function Widget() {
  const { id } = useParams();
  const [coins, setCoins] = useState([]);
  const recordsPerPage = 10;
  const { handleGetCookies } = useContext(AuthContext);
  const [refersh, setRefersh] = useState('1');
  const count = useRef(0);
  

  useEffect(() => {
    const fetchCoins = async () => {
      try {
        const response = await backend_api.post(`get-coin-details.php?symbol=${id}`);
        if (response && response.data) {
          setCoins(response.data.data);

        }
      } catch (error) {
        console.error('Error fetching coins:', error);
      }
    };
    if (id) {
      fetchCoins();
    }
  }, [id]);


  useEffect(() => {
    console.log(coins)
    if (coins && coins.symbol) {
      // Script URLs
      const scriptSources = [
        `${process.env.PUBLIC_URL}/assets/vendor/numeral/numeral.min.js`,
        `${process.env.PUBLIC_URL}/assets/vendor/amstock/amcharts.js`,
        `${process.env.PUBLIC_URL}/assets/vendor/amstock/themes/dark.js`,
        `${process.env.PUBLIC_URL}/assets/vendor/amstock/serial.js`,
        `${process.env.PUBLIC_URL}/assets/vendor/amstock/amstock.js`,
        `${process.env.PUBLIC_URL}/assets/vendor/amstock-dataloader/dataloader.min.js`,
        `${process.env.PUBLIC_URL}/assets/vendor/amstock/plugins/export/export.min.js`,
        `${process.env.PUBLIC_URL}/assets/vendor/moment/moment.min.js`,
        `${process.env.PUBLIC_URL}/assets/vendor/moment/locales.min.js`,
        `${process.env.PUBLIC_URL}/assets/vendor/datatables/jquery.dataTables.min.js`,
        `${process.env.PUBLIC_URL}/assets/js/coin.js`,
        `${process.env.PUBLIC_URL}/assets/js/facebook.min.js`,
        `${process.env.PUBLIC_URL}/assets/js/twitter.min.js`,
      ];

      // Load scripts sequentially
      const loadScriptsSequentially = (sources, callback) => {
        if (sources.length === 0) {
          callback();
          return;
        }

        loadScript(sources[0], (err) => {
          if (err) {
            console.error(`Failed to load script: ${sources[0]}`, err);
          } else {
            loadScriptsSequentially(sources.slice(1), callback);
          }
        });
      };

      loadScriptsSequentially(scriptSources, () => {
        if (window.updateCoinData && count.current === 0) {
          window.updateCoinData(coins);
        }
      });

      // Clean up function
      return () => {
        // Note: You can't easily remove dynamically loaded scripts, but you can manage their state if needed
      };
    }
  }, [coins]);


  useEffect(() => {
    if (coins && coins.symbol) {
      if (window.initRealTimeFeed) {
        window.initRealTimeFeed();
      }
    }
  }, [coins]);

  return (

    <section id="content" class="container">
      <section id="coin-header">
        <div class="container mx-auto">
          <div class="grid grid-cols-1 lg:grid-cols-3 py-2 lg:py-12">
            <div class="flex flex-col flex-auto mx-0 my-0 lg:my-2 lg:mx-2">
              <div class="flex items-center space-x-2">
                <img src={`${BACKEND_API}upload/images/coins/${coins?.logo}`} alt={coins?.symbol} class="w-10 h-10" />
                <p class="font-medium text-md">{coins?.name}</p>
                <p class="font-medium text-md tracking-wider">({coins?.symbol})</p>
              </div>
              <div class="flex mt-4 space-x-2">
                <p class="font-mono text-3xl leading-none tracking-tight">
                  <span className={`coin-row coin-row-${coins?.symbol}`} data-symbol={`${coins?.symbol}`}>
                    $<span className="coin-price-value">{formatPrice(coins && coins.price ? coins.price : 0)}</span>
                  </span>
                </p>
                <div class="flex">
                  {coins && parseFloat(coins.change_pct) < 0 ? (
                    <svg class="shrink-0 fill-current text-green-500 mx-2 mb-px text-red-800" viewBox="0 0 24 24" width="24" height="24" stroke={coins && parseFloat(coins.change_pct) < 0 ? `#bb3232` : `#008000`} stroke-width="2" fill={coins && parseFloat(coins.change_pct) < 0 ? `#ff0000` : `#008000`} stroke-linecap="round" stroke-linejoin="round"><line x1="12" y1="5" x2="12" y2="19"></line><polyline points="19 12 12 19 5 12"></polyline></svg>
                  ) : (
                    <svg class="shrink-0 fill-current text-green-500 mx-2 mb-px text-success-800" viewBox="0 0 24 24" width="24" height="24" stroke={coins && parseFloat(coins.change_pct) < 0 ? `#bb3232` : `#008000`} stroke-width="2" fill={coins && parseFloat(coins.change_pct) < 0 ? `#ff0000` : `#008000`} stroke-linecap="round" stroke-linejoin="round" ><line x1="12" y1="19" x2="12" y2="5"></line><polyline points="5 12 12 5 19 12"></polyline></svg>
                  )}
                  <p class={`font-mono font-medium text-md leading-none mb-px ${coins && parseFloat(coins.change_pct) < 0 ? 'change-down' : 'change-up'}`}>{parseFloat(coins.change_pct)}%</p>
                </div>
              </div>
              <div class="flex mt-4 space-x-2">
                <a href="https://dex.blockstar.site/" rel="noreferrer" class="green waves-effect waves-light btn" target="_blank"><i class="material-icons left">arrow_upward</i>Buy {id}</a>

                <a href="https://dex.blockstar.site/" rel="noreferrer" class="red waves-effect waves-light btn" target="_blank"><i class="material-icons left">arrow_downward</i>Sell {id}</a>
              </div>
            </div>
            <div class="text-center col-span-2 lg:col-span-2 grid grid-cols-2 lg:grid-cols-4 gap-2 lg:gap-4 items-center mx-0 my-4 lg:my-2 lg:mx-2 rounded-xl border">
              <div class="p-4 lg:p-4">
                <p class="text-sm font-medium leading-none">Market Cap</p>
                <p class="mt-2 font-mono text-md leading-none">${convertToInternationalCurrencySystem(coins && coins.market_cap ? coins.market_cap : 0)}</p>
              </div>

              <div class="p-4 lg:p-4">
                <p class="text-sm font-medium leading-none">Volume</p>
                <p class="mt-2 font-mono text-md leading-none">${convertToInternationalCurrencySystem(coins && coins.volume_ccy ? coins.volume_ccy : 0)}</p>
              </div>

              <div class="p-4 lg:p-4">
                <p class="text-sm font-medium leading-none">Supply</p>
                <p class="mt-2 font-mono text-md leading-none">{convertToInternationalCurrencySystem(coins && coins.supply ? coins.supply : 0)}</p>
              </div>

              <div class="p-4 lg:p-4">
                <p class="text-sm font-medium leading-none">24h Change</p>
                <p class={`mt-2 font-mono text-md leading-none ${coins && parseFloat(coins.change_pct) < 0 ? 'text-red' : 'text-success'}`}>${formatPrice(coins && coins.change ? coins.change : 0, 5)}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="row">
          <div class="col s12">
            <div id="coin-chart">
              <div id="coin-chart-preloader">
                <div class="preloader-wrapper small active">
                  <div class="spinner-layer spinner-green-only">
                    <div class="circle-clipper left">
                      <div class="circle"></div>
                    </div>
                    <div class="gap-patch">
                      <div class="circle"></div>
                    </div>
                    <div class="circle-clipper right">
                      <div class="circle"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="coin-chart-comparison">
                <select id="select-comparison" multiple="multiple"></select>
              </div>
              <div id="coin-chart-container"></div>
            </div>
          </div>
        </div>
      </section>
    </section >


  );
};
